<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Navegación en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Elementos de apoyo
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Elementos de apoyo
          </h2>

          <div  class="row p-contenedor-30 m-t-20">
            <div  class="col-lg-12">
                <p  class="f-s-24"> Conoce los elementos de apoyo para consultar la información presentada en el sitio PREP 2024 Elecciones Federales. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
              <img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-1.svg" alt="">
            </div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Elementos de accesibilidad y apoyo </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-20">
            <div  class="col-lg-12 imagenes-100 text-center">
              <img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-1.svg" alt="">
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-20 sn-m-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div  class="col-lg-11 col-10">
                <p  class="f-s-24"><strong >Preguntas frecuentes:</strong> redirecciona a la página sobre los conceptos clave y navegación del sitio. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 sn-m-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >2</strong></p>
            </div>
            <div  class="col-lg-11 col-10">
                <p  class="f-s-24"><strong >Centro de Ayuda:</strong> sitio independiente en el que se explican aspectos relacionados con el proceso electoral. ​ </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 sn-m-b p-b-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >3</strong></p>
            </div>
            <div  class="col-lg-11 col-10">
                <p  class="f-s-24"><strong >Tamaño de fuente y Tema del sitio:</strong> brinda opciones de accesibilidad para una mejor experiencia de usuario. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 b-color-azul color-b p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-2.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón Signo de Interrogación (?)​ </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo b-color-azul color-b p-t-20">
            <div  class="col-lg-12 col-10">
                <p  class="f-s-24"> Al dar clic en el ícono (?) se despliega una ventana con la información del tema que se está señalando. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 b-color-azul p-b-20">
            <div  class="col-lg-12 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-2.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-3.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón Ver Actas RP </p>
            </div>
          </div>
          <div  class="row p-contenedor-30">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24"> Redirige al apartado <strong >Representación Proporcional,</strong> donde se muestra la cantidad de votos en las Actas PREP de Casilla Especial de Representación Proporcional. &nbsp; </p>
                <p  class="f-s-24"> Se encuentra en Votos por Partido Político y Candidatura Independiente, nivel Nacional, Circunscripción y Entidad. </p>
            </div>
            <div  class="col-lg-6 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-3.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 b-color-azul color-b p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-4.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón de Indicación (i)​ </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo b-color-azul color-b p-t-20">
            <div  class="col-lg-12 col-10">
                <p  class="f-s-24"> Muestra una indicación, la cual puede encontrarse en dos formas: </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 b-color-azul p-b-20">
            <div  class="col-lg-12 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-4.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 b-color-azul color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div  class="col-lg-11 col-10 sn-m-b">
                <p  class="f-s-24"> Dentro de un globo o tooltip, que se visualiza al pasar el cursor sobre el botón (i). </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 b-color-azul p-b-20 color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >2</strong></p>
            </div>
            <div  class="col-lg-11 col-10 sn-m-b">
                <p  class="f-s-24"> Expuesta sobre la información en el sitio. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-5.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón Ver </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 p-b-20">
            <div  class="col-lg-12 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-5.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div  class="col-lg-11 col-10 sn-m-b">
                <p  class="f-s-24"> Indica donde fue digitalizada el Acta o si se encuentra en proceso, mostrando el ícono correspondiente.​ </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20">
            <div  class="col-lg-12 col-10">
                <p  class="f-s-24"> Al dar clic, abre la imagen del Acta digitalizada. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20 b-color-azul color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-6.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Imagen procedente de | Íconos </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 p-b-20 b-color-azul color-b">
            <div  class="col-lg-12 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-6.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 b-color-azul color-b p-b-20">
            <div  class="col-lg-12">
                <p  class="f-s-24"> Los íconos muestran si el Acta: </p>
                <ol  class="f-s-24">
                  <li > Está en proceso​ </li>
                  <li > Fue digitalizada con Escáner​ </li>
                  <li > Fue digitalizada con el Móvil </li>
                  <li > Es procedente de Urna Electrónica (Coahuila y Jalisco) </li>
                </ol>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20 p-b-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-7.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Detalle de la votación​ </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 p-b-20">
            <div  class="col-lg-12 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/voto_acta.png" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20">
            <div  class="col-lg-6">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >1</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> El botón <strong >Seleccionar</strong> permite elegir una tarjeta para visualizar el detalle de la votación.​ </p>
                  </div>
                </div>
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >2</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> El botón <strong >Quitar selección,</strong> permite deseleccionar la tarjeta. </p>
                  </div>
                </div>
            </div>
            <div  class="col-lg-6">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >3</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> El botón <strong >Ver detalle</strong> se activa al seleccionar una tarjeta y al dar clic despliega la ventana Detalle. </p>
                  </div>
                </div>
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >4</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> En la ventana <strong >Detalle</strong> se puede visualizar el total de votos y porcentaje que tienen hasta el momento de hasta tres opciones. </p>
                  </div>
                </div>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20 b-color-azul color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-8.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón Descargar </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20 b-color-azul color-b p-b-20">
            <div  class="col-lg-6">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >1</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> Este botón se encuentra en el apartado <strong >Base de Datos,</strong> mediante éste, puedes realizar la descarga de los archivos correspondientes. </p>
                  </div>
                </div>
            </div>
            <div  class="col-lg-6 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-8.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-9.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Botón Regresar </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20 p-b-20">
            <div  class="col-lg-6">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >1</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> El botón <strong >Regresar</strong> te redirige a la consulta previa. </p>
                  </div>
                </div>
            </div>
            <div  class="col-lg-6 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-9.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20 b-color-azul color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-10.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Mostrar | Ocultar Menú </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20 b-color-azul color-b p-b-20">
            <div  class="col-lg-6">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >1</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> Al dar clic sobre estos botones se <strong >oculta</strong> o <strong >muestra</strong> el menú. </p>
                  </div>
                </div>
            </div>
            <div  class="col-lg-6 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-10.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-11.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Enlaces </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div  class="col-lg-11 col-10">
                <p  class="f-s-24"> ​Al dar clic en los temas subrayados, te redirigen al <strong >Centro de Ayuda</strong> para el detalle de la información. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20 p-b-20 align-items-center">
            <div  class="col-lg-4 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-11-a.svg" alt=""></div>
            <div  class="col-lg-8 imagenes-100 text-center p-t-20-movil"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/apoyo-11-b.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20 b-color-azul color-b">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-12.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Herramienta de zoom </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20 b-color-azul color-b p-b-20 m-b-50">
            <div  class="col-lg-4">
                <div  class="row">
                  <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                      <p ><strong >1</strong></p>
                  </div>
                  <div  class="col-lg-11 col-10">
                      <p  class="f-s-24"> Aumenta o disminuye el tamaño del <strong >Mapa Electoral</strong> para una mejor visualización de éste. </p>
                  </div>
                </div>
            </div>
            <div  class="col-lg-8 imagenes-100 text-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/herramienta_zoom.png" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-13.svg" alt=""></div>
            <div  class="col-lg-5 col-10">
                <p  class="f-s-24 f-w-500"> Tarjeta de información en el Mapa Electoral </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo p-t-20">
            <div  class="col-lg-1 col-2 imagenes-50 text-center sn-p-r">
                <p ><strong >1</strong></p>
            </div>
            <div class="col-lg-11 col-10">
                <p  class="f-s-24"> Se muestra al pasar el cursor sobre el Mapa Electoral, indicando la información correspondiente al
                  Distrito seleccionado, al dar clic sobre ésta, te redirige al detalle de la votación 
                </p>
            </div>
          </div>
          <div  class="row justify-content-center">
            <div  class="col-lg-8 align-items-center"><img  class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/Mapa_13.png" ></div>
          </div>
          <div  class="row p-contenedor-30 m-b-50">
            <div  class="col-lg-12 f-s-16">
                <p  class="f-w-500"> Las imágenes utilizadas en esta infografía son representativas.&nbsp; </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Navegacion_en_PREP_Infografias/Elementos_de_Apoyo_PREP.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
